.text-left{
  text-align: left !important;
}

.home-event-container {
  display: grid;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f7f7f7;
}

.home-event-wrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-self: right;
  align-items: baseline;
}


.home-event-header {
  width: 28%;
}

.home-event-cards {
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 50px;
  overflow-x: scroll;
}

.home-event-cards::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1059px) {
  .home-event-wrapper {
    width: 100%;
    flex-direction: column;
    overflow-x: scroll;
    justify-self: left;
  }

  .home-event-wrapper::-webkit-scrollbar{
    display: none;
  }

  .home-event-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    padding-left: 10%;
    padding-right: 5%;
    margin-bottom: 30px;
  }

  .home-event-cards{
    margin-left: 5%;
    padding-top: 50px;
  }
}

@media (max-width: 600px){
}
