.article-head {
  background-size: cover !important;
  position: relative;
  text-align: center;
  clear: both;
  height: calc(100vh - 72px);
}

.article-head::after {
  content: "";
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.54;
}
.article-head-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  z-index: 44;
  padding: 60px 15px;
}

.article-category > a {
  font-size: 0.87rem;
  letter-spacing: inherit;
  font-weight: inherit;
  padding: 5px 10px;
  color: #fff;
  background: #fc8e44;
  border: 1px solid #fc8e44;
}

.article-category > a:hover {
  background: none;
  color: #fc8e44;
  transition: 0.3s all;
}
.article-category-button {
  margin: 2px;
  background: #fc8e44;
  color: #fff;
  border-color: #fc8e44;
}

.article-title {
  color: #fff;
  margin: 20px 0;
  font-size: 80px;
  line-height: 90px;
  font-family: karla;
}
.article-date {
  color: #fff;
  position: relative;
}

.article-author-text {
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.article-container-gen {
  width: 100% !important;
  max-width: 1200px !important;
  margin-right: auto;
  margin-left: auto;
}

.article-content-wrapper {
  max-width: 1000px !important;
  padding: 100px 15px;
  font-family: "Karla";
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: #5e5e5e;
  font-style: normal;
  margin-bottom: 1rem;
}

.article-content-wrapper img{
  max-width: 100%;
  height: auto;
}

.article-meta {
  margin: 95px 0;
}

.article-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.article-social-share {
  display: flex;
  justify-content: center;
  text-align: center;
}
.share-button {
  margin: 3px 8px;
  line-height: 50px;
}

@media (max-width: 767px) {
  .article-title {
    font-size: 35px;
    line-height: 40px;
  }
}

@media (min-width: 576px) {
  .article-container-gen {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .article-container-gen {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .article-container-gen {
    max-width: 960px;
  }
}

@media screen and (min-width: 992px) {
  .article-head {
    height: calc(100vh - 100px);
  }
}
