.login-container {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  max-width: 400px;
}
.login-avatar {
  margin-bottom: 40px;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .login-form {
    width: 400px;
  }
  .login-form-container {
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.1);
    padding: 80px;
  }
}
@media screen and (min-width: 1066px) {
  .login-container {
    height: calc(100vh - 100px);
  }
}
@media screen and (max-width: 767px) {
  .login-form {
    width: calc(100vw - 20px);
  }
}


