.unsubscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.unsubscribe-box {
  margin-top: 15%;
  height: max-content;
  width: max-content;
  border: solid 2px #fc8d43;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: white;
  border-radius: 5px;
  position: relative;
  font-family: karla;
  margin-bottom: 1.2em;
}
.unsubscribe-title {
  background-color: #fc8e44;
  font-weight: 700;
  padding: 0.5em 1em;
  width: 100%;
  display: inline-block;
}
.unsubscribe-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #5e5e5e;
  padding: 30px;
  text-align: center;
  color: black;
  border-radius: 5px;
  background: #fff;
}
