.donation-card-container {
  margin-bottom: 30px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.donation-card-item {
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease;
}

.donation-card-image {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.donation-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 60px;
}

.donation-card-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  color: #222;
  margin-bottom: 15px;
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
  text-decoration: none;
  font-family: "karla";
}

.donation-card-text {
  font-family: "Karla";
  font-weight: 400;
  color: #5e5e5e;
  font-style: normal;
  line-height: 1.5em;
  max-height: 10.5em;
  margin-bottom: 20px;
  overflow: hidden;
}

.donation-card-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

@media (min-width: 600px) {
  .donation-card-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 794px) {
  .donation-card-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .donation-card-container {
    flex: 0 0 33.33333%;
    max-width: 33.333%;
  }
}