.text-justify{
  text-align: justify !important;
}

.text-center{
  text-align: center !important;
}

.devotion-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.devotion-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  height: auto;
  align-items: center;
  justify-content: center;
}

.devotion-content {
}

.devotion-header-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.devotion-header{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1059px) {
  .devotion-container {
    width: 80%;
    align-items: center;
    justify-content: center;
  }

  .devotion-content{
      padding-left: 0;
  }
  .devotion-container div{
    width: 100%;
  }
}

@media (max-width: 600px) {
}
