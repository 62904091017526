.navbar-header {
  display: none;
  position: relative;
  z-index: 999;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  color: white;
}
.color-change {
  transition: all 0.1s;
  background-color: #f7f7f7;
}
.login-logo {
  margin-bottom: 30px;
}

.navbar-container {
  width: 100%;
  max-width: 1850px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100px;
}

.navbar-main {
  display: grid;
  padding: 0 15px;
  margin: auto;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  padding: 15px 0 17px;
  color: black;
}
.navbar-logo-image {
  max-height: 150px;
  width: auto;
}
.navbar-menu-box {
  display: flex;
  list-style: none;
  font-family: karla;
  font-weight: 700;
  line-height: 1.7em;
  margin-bottom: 0;
}

.active {
  color: #fc8e44;
}
.inactive {
  color: #222;
}
.inactive-home {
  color: white;
}

.navbar-menu-item {
  margin: 0 20px;
  padding: 15px 5px;
  position: relative;
}
.navbar-menu-item a {
  text-decoration: none;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 15px;
}
.navbar-menu-item a:hover {
  color: #fc8e44;
  transition: all 0.3s ease;
}
.navbar-sub-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  background: #fff;
  display: none;
  z-index: 1;
  padding: 20px 0 21px;
  transition: all 0.2s;
  white-space: nowrap;
  list-style: none;
}

.navbar-menu-item:hover > .navbar-sub-menu {
  display: block;
  opacity: 1;
}

.sub-menu-item {
  color: black;
  cursor: pointer;
  min-width: 200px;
  padding: 5px 10px;
}
.sub-menu-item > a {
  color: inherit;
}

.sub-menu-item:hover {
  color: #fc8e44;
}
.sub-menu-item:hover > a {
  margin-left: 20px;
  transition: all 0.2s ease;
}
.sub-menu-bullet {
  visibility: hidden;
  color: #fc8e44;
}
.sub-menu-item:hover .sub-menu-bullet {
  visibility: visible;
  transition: all 0.2s ease;
}

.navbar-donate-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar-donate {
  cursor: pointer;
  align-self: flex-end;
}
.navbar-donate > a {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 15px 33px;

  color: #fff;
  background: #fc8e44;
  border: 1px solid #fc8e44;
}

.navbar-donate-scroll > a:hover {
  background: inherit;
}
.navbar-donate-default > a:hover {
  background: #fff;
}

.navbar-donate > a:hover {
  color: #fc8e44;
  transition: 0.3s all;
}

@media screen and (min-width: 576px) {
  .navbar-container {
    max-width: 720px;
  }
}
@media screen and (min-width: 768px) {
  .navbar-container {
    max-width: 960px;
  }
}
@media screen and (min-width: 1066px) {
  .navbar-container {
    max-width: 1140px;
  }
  .navbar-header {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .navbar-container {
    max-width: 1850px;
  }
}
