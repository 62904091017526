.articles-banner {
  text-align: center;
  min-height: 100px;
  height: 100%;
}
.articles-img {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.articles-banner-title {
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 700;
  color: #fff;
  z-index: 44;
  font-style: normal;
  line-height: 1.4;
}

.articles-cards-wrapper {
  padding: 100px 0 70px;
}
.articles-cards-container {
  width: 100% !important;
  max-width: 1200px !important;
  margin-right: auto;
  margin-left: auto;
}

.articles-cards-row {
  display: flex;
  flex-wrap: wrap;
}
.articles-cards-col {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.articles-cards-inner {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

@media screen and (min-width: 576px) {
  .articles-cards-container {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .articles-cards-container {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .articles-cards-container {
    max-width: 960px;
  }
}
@media screen and (max-width: 1440px) {
  .articles-banner-title {
    font-size: 80px;
  }
  .articles-img {
    min-height: 500px;
  }
}

@media screen and (max-width: 1199px) {
  .articles-banner-title {
    font-size: 55px;
  }
  .articles-img {
    min-height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .articles-banner-title {
    font-size: 35px;
  }
  .articles-img {
    min-height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .articles-cards-wrapper {
    padding: 30px 0 0px;
  }
}
@media screen and (max-width: 991px) {
  .articles-cards-wrapper {
    padding: 80px 0 50px;
  }
}
@media screen and (max-width: 1440px) {
  .articles-cards-wrapper {
    padding: 90px 0 60px;
  }
}
