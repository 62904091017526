.article-card-container {
  margin-bottom: 30px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.article-card-item {
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease;
}
.article-card-image {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.article-card-info {
  padding: 30px;
}
.article-card-date {
  display: flex;
  align-items: center;
}

.article-card-date-icon {
  padding-top: 5px;
  margin-right: 5px;
}
.article-card-date-text {
  font-family: "karla";
  font-size: 13px;
  line-height: 1.3;
}
.article-card-title {
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
  color: #222;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 15px;
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
  text-decoration: none;
  font-family: "karla";
}
.article-card-title:hover {
  color: #fc8e44;
}
.article-card-text {
  font-family: "Karla";
  font-weight: 400;
  color: #5e5e5e;
  font-style: normal;
  line-height: 1.5em;
  max-height: 10.5em;
  margin-bottom: 20px;
  overflow: hidden;
}
.article-card-author {
  display: flex;
  align-items: center;
  color: #222;
  font-size: 13px;
  font-weight: 700;
}

.article-card-author-avatar {
  margin-right: 15px;
}

@media (min-width: 600px) {
  .article-card-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 794px) {
  .article-card-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1024px) {
  .article-card-container {
    flex: 0 0 33.33333%;
    max-width: 33.333%;
  }
}
