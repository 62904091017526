.magazines-banner {
  text-align: center;
  min-height: 100px;
  height: 100%;
}
.magazines-img {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.magazines-banner-title {
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 700;
  color: #fff;
  z-index: 44;
  font-style: normal;
  line-height: 1.4;
}
  
  .magazines-cards-wrapper {
    padding: 100px 0 70px;
  }
  .magazines-cards-container {
    width: 100% !important;
    max-width: 1200px !important;
    margin-right: auto;
    margin-left: auto;
  }
  
  .magazines-cards-row {
    display: flex;
    flex-wrap: wrap;
  }
  .magazines-cards-col {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
  .magazines-cards-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  
  @media screen and (min-width: 576px) {
    .magazines-cards-container {
      max-width: 540px;
    }
  }
  @media screen and (min-width: 768px) {
    .magazines-cards-container {
      max-width: 720px;
    }
  }
  @media screen and (min-width: 992px) {
    .magazines-cards-container {
      max-width: 960px;
    }
  }
  @media screen and (max-width: 1440px) {
    .magazines-banner-title {
      font-size: 80px;
    }
    .magazines-img {
      min-height: 500px;
    }
  }
  
  @media screen and (max-width: 1199px) {
    .magazines-banner-title {
      font-size: 55px;
    }
    .magazines-img {
      min-height: 400px;
    }
  }
  @media screen and (max-width: 767px) {
    .magazines-banner-title {
      font-size: 35px;
    }
    .magazines-img {
      min-height: 300px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .magazines-cards-wrapper {
      padding: 30px 0 0px;
    }
  }
  @media screen and (max-width: 991px) {
    .magazines-cards-wrapper {
      padding: 80px 0 50px;
    }
  }
  @media screen and (max-width: 1440px) {
    .magazines-cards-wrapper {
      padding: 90px 0 60px;
    }
  }
  