.about-us-mission {
  height: auto;
  display: grid;
  align-items: center;
  padding-bottom: 80px;
}

.mission-container {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 40px;
}

.mission-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  align-self: center;
  justify-self: right;
}

.mission-content-container {
  align-self: center;
  justify-self: center;
  width: 80%;
  background: #ffffff;
  height: auto;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 10%);
}

.mission-content {
  width: 100%;
  height: 70px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.mission-content:hover {
  background-color: #fc8e44 !important;
}

.mission-line {
  width: auto;
  height: 2px;
  background-color: rgb(216, 216, 216);
}

@media screen and (max-width: 576px) {
  .about-us-mission {
    height: auto;
    display: grid;
    align-items: center;
    padding-bottom: 40px;
  }

  .mission-container {
    gap: 40px;
  }

  .mission-content-container {
    width: 90%;
  }

  .mission-content {
    height: 80px;
  }
}
