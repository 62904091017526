.event-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}
.event-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.1);
}

.event-head {
  text-align: left;
}
.event-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  max-width: 500px;
}

.event-description {
  font-size: 16px;
  font-weight: 400;
  font-family: karla;
  max-width: 400px;
}
.event-register {
  cursor: pointer;
  margin-top: 40px;
}
.event-register > a {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 20px 36px;
  color: #fff;
  background: #fc8e44;
  border: 1px solid #fc8e44;
}

.event-register > a:hover {
  background: #fff;
  color: #fc8e44;
  transition: 0.3s all;
}

.event-title {
  color: #fc8e44;
  font-size: 40px;
  font-weight: 700;
  font-family: karla;
}
.event-date {
  color: black;
  position: relative;
}

.event-social-share {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  text-align: center;
}
.share-button {
  margin: 3px 8px;
  line-height: 50px;
}
.event-image {
  max-width: 500px;
  overflow: hidden;
}

@media (max-width: 1067px) {
  .event-container {
    flex-direction: column;
    align-items: center;
    padding:0;
  }
}

