.events-banner {
  text-align: center;
  min-height: 100px;
  height: 100%;
  padding: 0px 0 120px 0;
}
.events-img {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.events-banner-title {
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 700;
  color: #fff;
  z-index: 44;
  font-style: normal;
  line-height: 1.4;
}

.events-cards-wrapper {
  padding: 100px 0 70px;
}
.events-upcoming-container {
  margin-bottom: 20px;
  margin: 0 10px 0 10px;
  padding: 0 10px 0 10px;
}
.events-upcoming {
  position: relative;
  z-index: 10;
  text-align: center;
}

.events-upcoming-subtitle {
  display: inline-block;
  padding: 5px 0 4px;
  color: #fc8e44;
  position: relative;
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(94, 94, 94, 0.15);
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 2.67px;
  text-transform: uppercase;
}
.events-upcoming-title {
  font-family: "Karla";
  font-weight: 700;
  color: #222;
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
}
.events-cards-container {
  width: 100% !important;
  max-width: 1200px !important;
  margin-right: auto;
  margin-left: auto;
}

.events-cards-row {
  display: flex;
  flex-wrap: wrap;
}
.events-cards-col {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
}
.events-cards-inner {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 10px 0 10px;
  /* padding: 0 10px 0 10px; */
}

@media screen and (min-width: 576px) {
  .events-cards-container {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .events-cards-container {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .events-cards-container {
    max-width: 960px;
  }
}
@media screen and (max-width: 1440px) {
  .events-banner-title {
    font-size: 80px;
  }
  .events-img {
    min-height: 500px;
  }
}

@media screen and (max-width: 1199px) {
  .events-banner {
    padding: 0 0 97px 0;
  }
  .events-banner-title {
    font-size: 55px;
  }
  .events-img {
    min-height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .events-banner-title {
    font-size: 35px;
  }
  .events-img {
    min-height: 300px;
  }
  .events-banner {
    padding: 0 0 57px 0;
  }
  .events-upcoming-title {
    font-size: 30px;
    line-height: 35px;
  }
}

@media screen and (max-width: 767px) {
  .events-cards-wrapper {
    padding: 30px 0 0px;
  }
}
@media screen and (max-width: 991px) {
  .events-cards-wrapper {
    padding: 80px 0 50px;
  }
}
@media screen and (max-width: 1440px) {
  .events-cards-wrapper {
    padding: 90px 0 60px;
  }
}
