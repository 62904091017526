.subscribe-window-container {
  max-width: 740px;
  min-height: 600px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.subscribe-window-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
}
.subscribe-window-image {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.subscribe-window-caption > h2 {
  text-align: center;
  z-index: 10;
  position: relative;
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 48px;
}

.subscribe-window-text > p {
  color: #fff;
  font-family: "Karla", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}
.subscribe-window-form-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscribe-window-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subscribe-window-input > .subscribe-window-form-item {
  margin: 8px;
}

@media screen and (min-width: 992px) {
  .subscribe-window-input {
      flex-direction: row;
  }
  .subscribe-window-caption > h2 {
    font-size: 42px;
  }
}
