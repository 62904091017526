.event-card-container {
  background-color: #fff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  z-index: 2;
  position: relative;
  margin-bottom: 80px;
}

.event-card-container:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.01);
}

.event-card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 450px;
  padding: 74px 40px 60px 40px;
}

.event-card-date {
  width: 90px;
  height: 90px;
  color: white;
  transform: translateY(-50%);
  position: absolute;
  top: 0;
  background-color: #fc8e44;
  box-shadow: 0 5px 20px 0 rgba(252, 142, 68, 0.4);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.event-card-day {
  font-weight: 400;
  font-size: 2.1em;
  font-family: "karla";
  line-height: 1;
}
.event-card-month {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  font-family: "karla";
}
.event-card-title {
  padding-top: 0;
  margin-bottom: 19px;
  word-break: break-word;
  letter-spacing: -0.5px;
  font-family: "karla";
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  color: #222;
}
.event-card-register {
  cursor: pointer;
  margin-top: 20px;
}
.event-card-register > a {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 22px 38px;
  background: #fff;
  color: #fc8e44;
  border: 1px solid #eee;
}

.event-card-register > a:hover {
  background: #5e5e5e;
  color: #fff;
  border-color: #5e5e5e;
  transition: 0.3s all;
}

.event-card-flex {
  display: flex;
  margin-top: 20px;
}
.event-icon {
  margin-right: 20px;
  padding-top: 3px;
}

.event-card-flex-item > p {
  font-family: "Karla";
  line-height: 1.5;
  color: black;
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  max-width: 180px;
}

@media (max-width: 600px) {
  .event-card-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 600px) {
  .event-card-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 794px) {
  .event-card-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1024px) {
  .event-card-container {
    flex: 0 0 33.33333%;
    max-width: 33.333%;
  }
}
@media screen and (max-width: 767px) {
  .event-card-title {
    font-size: 24px;
  }

}