.home-article-container {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
}

.home-article-content {
  display: flex;
  flex-direction: row;
  width: 75%;
  overflow-x: scroll;
}

.home-article-content::-webkit-scrollbar {
  display: none;
}

.home-article-header {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-left: 20px;
  align-items: flex-end;
  justify-content: center;
  padding-right: 30px;
  background-color: #54595f;
}

.home-article-header-main {
  color: #fff !important;
  text-align: right !important;
}

@media (max-width: 600px) {
  .home-article-container {
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: column-reverse;
  }
  .home-article-header {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10%;
    align-items: flex-start;
  }
  .home-article-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: scroll;
  }
}
