footer {
  width: 100%;
  height: 500px;
  background-color: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  font-family: "karla";
  font-weight: 400;
  line-height: 1.5;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  width: 80%;
  height: 75%;
}

.footer-container {
  height: 60%;
  display: grid;
  /* align-self: center;
    justify-self: center; */
  grid-template-columns: 1fr 1fr 1fr;
}

.footer-items-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  gap: 40px;
  height: 100%;
  width: 80%;
}

.footer-item-header {
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-item-header-icon {
  display: none;
}

.footer-item-header h3 {
  font-weight: 600;
  color: white;
  margin-bottom: 0;
  font-size: 1.3em;
}

.footer-item-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 80%;
}

.footer-item-contents div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.footer-item-icon {
  height: 20px;
  width: 20px;
}

.footer-item-text {
  color: white;
}

@media screen and (max-width: 1156px) {
  .footer-wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1056px) {
  footer {
    height: 400px;
    font-size: 11px;
  }

  .footer-wrapper {
    width: 100%;
    height: 300px;
    grid-template-columns: 80%;
  }

  .footer-item-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 100px;
  }

  .footer-items-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  footer {
    height: auto;
    font-size: 10px;
    padding: 30px 10px 30px 10px;
    gap: 20px;
  }
  .footer-wrapper {
    width: 100%;
    height: auto;
  }

  .footer-item-header {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .footer-item-header-icon {
    display: flex;
    background-color: transparent;
    border: none;
  }
  .footer-item-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 10px;
    height: auto;
  }
  .footer-item-icon {
    height: 15px;
    width: 15px;
  }

  .footer-items-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 95%;
    border-bottom: 1px solid white;
  }
  .footer-item-header-icon-cross {
    transform: rotate(45deg);
  }
  .inactiveFooter {
    display: none;
  }

  .footer-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
}
