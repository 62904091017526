.pagination-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 83px;
}
.page-numbers {
  color: black;
  display: inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  margin: 8px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
}
.current-page {
  background: #fc8e44;
  color: white;
  padding: 10px 15px;
  box-shadow: 0 5px 20px rgba(252, 142, 68, 0.4);
}

.non-active {
  cursor: pointer;
}
.non-active:hover {
  color: #fc8e44;
}
.page-disabled {
  display: none;
}
