.contribution-card-container{
    min-width: 310px;
    height: 460px;
    position: relative;
    display: grid;
    align-items: flex-end;
}

.contribution-card-container img{
    position: absolute;
    z-index: -1;
    display: inline-block;
    max-width:100%;
    min-height: 100%;
    object-fit: cover;
}

.contribution-card-content{
    padding-bottom: 20px;
    display: grid;
    gap:10px;
    padding-left: 20px;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.contribution-card-caption{
    color:#ffffff;
    font-family: 'karla';
    padding: 0;
    margin: 0;
    font-weight: 700;
}


.contribution-card-heading{
    color: white;
    padding: 0;
    margin: 0;
    font-family: 'karla';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.2;
}

.contribution-card-heading:hover{
    color: #fc8e44;
}

.contribution-card-read-more{
    display: none;
    padding-left: 20px;
    padding-bottom: 20px;
}
