.tag {
  cursor: pointer;
  color: #fc8e44;
  font-family: "Karla";
  font-weight: 400;
  font-size: 18px;
  transition: 0.3s ease-out;
}
.tag:hover {
  color: rgba(252,142,68, 0.7);
}
