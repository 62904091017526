* {
  box-sizing: border-box;
  margin: 0;
}

/*Background colors*/

.orange-bg {
  background-color: #fc8e44;
}

.grey-bg {
  background-color: rgba(94, 94, 94, 0.1);
}

/*Font Styles*/

.italic {
  font-style: italic;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-color-white {
  color: white !important;
}

.about-us-page button {
  cursor: pointer;
}

.about-us-page {
  font-size: 16px;
}

.section-caption {
  width: fit-content;
  font-family: "karla";
  font-size: 16px;
  color: #fc8e44;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(94, 94, 94, 0.2);
  font-weight: 700;
  letter-spacing: 0.16em;
}

.section-header {
  font-family: "karla";
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin: 0;
  padding: 0;
}

.section-text {
  font-family: "karla";
  font-size: 16px;
  color: #5e5e5e;
  opacity: 0.8;
  line-height: 24px;
  text-align: justify;
}

.section-text-bold {
  font-family: "karla";
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  opacity: 0.8;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.white-text {
  color: white;
  opacity: 1;
}

.about-us-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../resources/images/about-us-banner.svg);
  text-align: center;
  min-height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-banner-title {
  font-family: "Playfair Display", serif;
  font-size: 5em;
  font-weight: 700;
  color: rgb(248, 248, 248);
  font-style: normal;
  line-height: 1.4;
}

.about-us-history {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  padding-top: 80px;
}

.history-container {
  height: 500px;
  width: 96%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  align-items: center;
}

.history-image {
  background: url("../../resources/images/history.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 500px;
  width: auto;
}

.history-content {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
}

.history-text {
  /* overflow-y: scroll; */
  padding-right: 40px;
  text-align: justify;
}

@media screen and (max-width: 576px) {
  .section-caption {
    padding: 0;
    margin: 0;
  }
  .section-header {
    font-size: 30px;
    line-height: 40px;
  }
  .section-text {
    opacity: 0.8;
    padding: 0;
    margin: 0;
  }
  .section-text-bold {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 20px;
  }
  .about-us-banner {
    min-height: 600px;
  }
  .about-us-banner-title {
    font-size: 4em;
  }
  .about-us-history {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .history-container {
    height: auto;
    width: 90%;
    gap: 50px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .history-image {
    height: 400px;
    width: 100%;
  }
  .history-content {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
  .history-text {
    padding-right: 0px;
    text-align: justify;
  }
}
@media screen and (max-width: 876px) {
  .history-container {
    height: auto;
    width: 90%;
    gap: 50px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .history-image {
    height: 400px;
    width: 100%;
  }
  .history-content {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
  .history-text {
    padding-right: 0px;
    text-align: justify;
  }
}



@media screen and (max-width: 1440px) {
  .about-us-banner-title {
    font-size: 80px;
  }
  .about-us-banner {
    min-height: 500px;
  }
}

@media screen and (max-width: 1199px) {
  .about-us-banner-title {
    font-size: 55px;
  }
  .about-us-banner {
    min-height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .about-us-banner-title {
    font-size: 35px;
  }
  .about-us-banner {
    min-height: 300px;
  }
}