.featured-container {
    background-color: #f7f7f7;
}
.featured-image-container{
    cursor: pointer;
}
.featured-image {
    max-height: 400px;
    object-fit: contain;
}

@media screen and (min-width: 576px) {
    .featured-image {
        max-height: 450px;
    }
}

@media screen and (min-width: 768px) {
    .featured-image {
        max-height: 500px;
    }
}

@media screen and (min-width: 992px) {
    .featured-image {
        max-height: 550px;
    }
}

@media screen and (min-width: 1440px) {
    .featured-image {
        max-height: 600px;
    }
}