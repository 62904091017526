.magazine-card-container {
  margin-bottom: 30px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.magazine-card-item {
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.09);
  transition: all 0.3s ease;
}
.magazine-card-info {
  padding: 15px;
}
.magazine-card-media {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.magazine-card-image {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.magazine-date-download {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-family: "karla";
}
.magazine-card-date {
  display: flex;
  align-items: center;
}

.magazine-card-date-icon {
  padding-top: 5px;
  margin-right: 5px;
}
.magazine-card-date-text {
  font-size: 13px;
  line-height: 1.3;
}
.magazine-card-download {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.magazine-card-download:hover {
  transition: all 0.3s ease;
  color: #fc8e44;
}

@media (min-width: 600px) {
  .magazine-card-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 794px) {
  .magazine-card-container {
    flex: 0 0 33.33333%;
    max-width: 33.333%;
  }
}

@media (min-width: 1024px) {
  .magazine-card-container {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
