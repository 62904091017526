.featured-page-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}

.featured-page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.1);
}

.featured-page-head {
  text-align: left;
}

.featured-page-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px 10px 30px;
}

.featured-page-description {
  font-size: 16px;
  font-weight: 400;
  font-family: karla;
  max-width: 400px;
}

.featured-page-title {
  color: #fc8e44;
  font-size: 40px;
  font-weight: 700;
  font-family: karla;
}

.featured-page-image-container {
  max-width: 1200px;
  overflow: hidden;
}

@media (max-width: 1067px) {
  .featured-page-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}