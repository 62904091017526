.navbar-mobile-container-open {
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  width: 100vw;
  scroll-behavior: unset;
  transition: all 0.3s;
}
.navbar-mobile-toggle-button{
  cursor: pointer;
}

.navbar-mobile-container-closed {
  display: flex;
  height: 72px;
  width: 100vw;
  transition: all 0.3s;
}

.navbar-mobile-container {
  width: 100vw;
  font-family: "karla";
  font-size: 1rem;
  font-weight: 500;
  color: #949494;
}

.navbar-mobile-logo-toggle {
  padding: 10px 20px 20px 20px;
  color: #fc8e44;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-mobile-logo-toggle button {
  background-color: transparent;
  border: none;
}

.navbar-mobile-logo-toggle button img {
  width: 30px;
  height: 30px;
}

.navbar-mobile-logo {
  position: relative;
  z-index: 1;
}
.navbar-mobile-logo img {
  height: 50px;
  width: 50px;
}

.navbar-mobile-toggle {
  cursor: pointer;
}
.navbar-mobile-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #ececec;
}

.navbar-mobile-item a {
  padding-left: 20px;
}
.navbar-mobile-item a:hover {
  color: #fc8e44;
}
.navbar-mobile-arrow {
  cursor: pointer;
  margin-right: 10px;
}
.navbar-mobile-sub-item {
  padding-left: 10px;
}

.navbar-mobile-item button {
  padding-right: 20px;
  background-color: transparent;
  border: none;
}

.navbar-mobile-donate {
  padding-top: 20px;
  padding-left: 20px;
}

@media screen and (min-width: 1066px) {
  .navbar-mobile-container-open {
    display: none;
  }
  .navbar-mobile-container-closed {
    display: none;
  }
}
