.donation-page-container {
  height: 100%;
}
.donation-banner {
  text-align: center;
  min-height: 100px;
  height: 100%;
}
.donation-img {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.donation-banner-title {
  /* margin-bottom: 10%; */
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 700;
  color: #fff;
  z-index: 44;
  font-style: normal;
  line-height: 1.4;
}

.donation-container {
  margin-top: -70px;
  padding: 100px;
  background-color: #fff;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.1);
}

.donation-form-container {
  width: 100%;
  float: none;
}
.donation-form-container > h1 {
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 50px;
  font-family: karla;
  font-weight: 700;
  color: #222;
  font-style: normal;
}
.donation-form-sub-title {
  font-family: karla;
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0 15px;
  padding: 0 0 5px;
  color: #fc8e44;
}
.donation-plan-summary {
  margin: 10px 0 15px;
  font-family: karla;
  font-size: 18px;
  font-weight: 700;
}
.donation-plan-total {
  margin: 10px 0 15px;
  font-family: karla;
  font-size: 22px;
  font-weight: 700;
}
.donation-plan-form {
  min-height: 100px;
}
.donation-plan-warning {
  color: red;
}
.donation-gen-container {
  width: 100% !important;
  max-width: 1200px !important;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 576px) {
  .donation-gen-container {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .donation-gen-container {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .donation-gen-container {
    max-width: 960px;
  }
}

@media screen and (max-width: 1440px) {
  .donation-container {
    padding: 100px;
  }
  .donation-banner-title {
    font-size: 80px;
  }
  .donation-img {
    min-height: 500px;
  }
  .donation-banner-title {
    margin-bottom: 25%;
  }
}

@media screen and (max-width: 1199px) {
  .donation-banner-title {
    font-size: 55px;
  }
  .donation-container {
    padding: 60px;
  }
  .donation-img {
    min-height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .donation-banner-title {
    font-size: 35px;
  }
  .donation-container {
    padding: 20px;
  }
  .donation-img {
    min-height: 300px;
  }
  .donation-form-container > h1 {
    font-size: 35px;
    line-height: 42px;
  }
}
