.about-us-qoute {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-moving {
  width: 80%;
  padding-top: 80px;
}

.qoute-container {
  padding-bottom: 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.qoute-qoute {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.qoute-qoute img {
  position: absolute;
  left: 10%;
  top: 0;
}

.qoute-main-qoute {
  width: 550px;
  font-size: 40px;
  font-family: Karla;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  letter-spacing: auto;
}

@media screen and (max-width: 576px) {
  .about-us-moving {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .qoute-container {
    padding-bottom: 40px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .qoute-qoute {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  .qoute-qoute img {
    display: none;
  }

  .qoute-main-qoute {
    width: 90%;
    font-size: 24px;
    font-family: Karla;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    letter-spacing: auto;
  }
}
