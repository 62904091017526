.statement-title {
  font-family: "Playfair Display", serif;
  font-size: 5em;
  font-weight: 700;
  color: #ffffff;
  font-style: normal;
  line-height: 1.4;
}
.statement-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../../resources/images/stmt_of_faith_banner.jpg);
  text-align: center;
  min-height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.statement-of-faith {
  height: auto;
  display: grid;
  align-items: center;
  padding-bottom: 80px;
}

.statement-container {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 40px;
}

.statement-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  align-self: center;
  justify-self: right;
}

.statement-content-container {
  align-self: center;
  justify-self: center;
  width: 80%;
  background: #ffffff;
  height: auto;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 10%);
}

.statement-content {
  width: 100%;
  height: 70px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.statement-line {
  width: auto;
  height: 2px;
  background-color: rgb(216, 216, 216);
}

@media screen and (max-width: 576px) {
  .statement-of-faith {
    height: auto;
    display: grid;
    align-items: center;
    padding-bottom: 40px;
  }

  .statement-container {
    gap: 40px;
  }

  .statement-content-container {
    width: 90%;
  }

  .statement-content {
    height: 80px;
  }
}
.statement-text-bold {
  font-family: "karla";
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  opacity: 0.8;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1440px) {
  .statement-title {
    font-size: 80px;
  }

  .statement-banner {
    min-height: 500px;
  }
}

@media screen and (max-width: 1199px) {
  .statement-title {
    font-size: 55px;
  }

  .statement-banner {
    min-height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .statement-title {
    font-size: 35px;
  }

  .statement-banner {
    min-height: 300px;
  }
  .statement-content {
    height: 100px;
    gap: 10px;
  }
}
@media screen and (max-width: 400px) {
  .statement-content {
    height: 120px;
    gap: 10px;
  }
}
