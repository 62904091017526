.article-card-landing-item {
  background-color: #fff;
  box-shadow: -30px 0 30px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.article-card-landing-item:hover {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
}

.article-card-landing-info {
  padding: 30px 30px 30px 10%;
  height: 400px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10% 80% 10%;
  gap: 10px;
}

.article-card-landing-content{
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.article-card-landing-date {
  margin-bottom: 28px;
}
.article-card-landing-date-text {
  font-size: 14px;
  font-weight: 700;
  font-family: "karla";
  color: white;
  background-color: #fc8e44;
  padding: 5px 15px;
  border-radius: 3px;
  box-shadow: 0 10px 25px 0 rgba(252, 142, 68, 0.3);
}
.article-card-landing-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  color: #222;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 15px;
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
  text-decoration: none;
  font-family: "karla";
}
.article-card-landing-readmore {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 15px;
}
.article-card-landing-readmore > h3 {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #fc8e44;
}
.article-card-landing-readmore > h3:hover {
  color: rgb(51, 50, 50);
  transition: all 0.3s ease;
}
.article-card-landing-text {
  font-family: "Karla";
  font-weight: 400;
  font-size: 16px;
  color: #5e5e5e;
  font-style: normal;
  margin-bottom: 10px;
  line-height: 1.5em;
  max-height: 13em;
  overflow: hidden;
}

@media (min-width: 600px) {
  .article-card-landing-info {
    height: 450px;
  }

  .article-card-landing-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .article-card-landing-text {
    max-height: 14.8em;
  }
}
@media (min-width: 794px) {
  .article-card-landing-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1024px) {
  .article-card-landing-container {
    flex: 0 0 33.33333%;
    max-width: 33.333%;
  }
}
