* {
  box-sizing: border-box;
  margin: 0;
}

.contact-us-page {
  font-size: 16px;
}

.contact-us-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  background-color: #f7f7f7;
}

.contact-us-title {
  font-family: "Playfair Display", serif;
  font-size: 5em;
  font-weight: 700;
  color: black;
  font-style: normal;
  line-height: 1.4;
}

.map-container {
  width: 100%;
  height: 40%;
  border: 0;
}

.contactUsContent {
  display: flex;
  flex-direction: column;
  margin-top: -40em;
  max-width: fit-content;
  margin-left: 5em;
  padding: 2em;
  border-radius: 15;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  position: relative;
  z-index: 1;
  background: white;
}

.contactDetails-icon {
  height: 25px;
  width: 25px;
}

.contactDetails-text {
  color: #5e5e5e;
  margin-left: 1em;
  font-size: 1em;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 80%;
  margin-bottom: 1em;
}

.contactDetails-content {
  display: flex;
}

.button {
  display: inline-block;
  padding: 0.75rem 1rem;
  margin-top: 1.618rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #fc8e44;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  user-select: none;
}
.button:hover {
  transition: 0.3s all;
  background-color: #fff;
  border: 1px solid #fc8e44;
  color: #fc8e44;
  box-shadow: none;
}

input,
textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  height: fit-content;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #55595c;
  border-left: 0;
  margin-bottom: 1em;
  outline: none;
}

.formData {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 1em;
}

.successMessage {
  color: rgb(58, 216, 37);
  text-align: center;
  height: 0.5em;
  margin-bottom: 0;
}

.failMessage {
  color: red;
  text-align: center;
  height: 0.5em;
  margin-bottom: 0;
}

@media screen and (max-width: 1400px) {
  .contact-us-title {
    font-size: 55px;
  }
}
@media screen and (max-width: 1100px) {
  .contact-us-title {
    font-size: 55px;
  }
}

@media screen and (max-width: 900px) {
  .contact-us-title {
    font-size: 55px;
  }

  .contact-us-banner {
    min-height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .contact-us-title {
    font-size: 35px;
  }

  .contact-us-banner {
    min-height: 200px;
  }
  .contactDetails-content {
    display: flex;
    flex-direction: column;
  }
  .contactDetails-text {
    display: block;
  }

  .contactUsContent {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
    border-radius: 15;
    box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
    background: white;
    margin-top: 0;
    margin-bottom: 0;
  }
}
