.gallery-page-container {
  height: 100%;
}
.gallery-banner {
  text-align: center;
  min-height: 100px;
  height: 100%;
}
.gallery-img {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-banner-title {
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 700;
  color: #fff;
  z-index: 44;
  font-style: normal;
  line-height: 1.4;
}
.gallery-sections-title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.gallery-sections-title h2 {
  font-family: karla;
  font-weight: 700;
  padding: 20px 2px 5px 2px;
  margin: 0;
  color: #fc8e44;
  font-size: 36px;
}
.gallery-sections-container {
  padding: 0 15px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  margin: 1.5em;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0.85em;
}
.gallery-image-item {
  display: inline-block;
  background: #fff;
  margin: 0 0 1.8em;
  width: 100%;
  -webkit-transition: 1s ease all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@media only screen and (max-width: 320px) {
  .gallery-sections-container {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }

}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .gallery-sections-container {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    padding: 10px 5px;
  }

}
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .gallery-sections-container {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    padding: 10px 5px;
  }

}
@media only screen and (min-width: 1201px) {
  .gallery-sections-container {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media screen and (max-width: 1440px) {
  .gallery-banner-title {
    font-size: 80px;
  }
  .gallery-img {
    min-height: 500px;
  }
  .gallery-sections-title > h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .gallery-banner-title {
    font-size: 55px;
  }
  .gallery-img {
    min-height: 400px;
  }
  .gallery-sections-title > h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .gallery-banner-title {
    font-size: 35px;
  }
  .gallery-img {
    min-height: 300px;
  }
  .gallery-sections-title > h2 {
    font-size: 20px;
  }
}
