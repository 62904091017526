.home-text-caption {
  display: inline-block;
  padding: 5px 0 4px;
  color: #fc8e44;
  position: relative;
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(94, 94, 94, 0.15);
  font-weight: 700;
  line-height: 1;
  font-family: "karla";
  text-transform: uppercase;
  letter-spacing: 2.7px;
  font-size: 16px;
}

.home-text-main {
  font-family: "Karla";
  font-weight: 700;
  color: #222;
  font-style: normal;
  font-size: 40px;
  text-align: left;
  line-height: 1.1em;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.home-text-paragraph-big {
  font-family: "Karla";
  font-weight: 700;
  color: #222;
  font-style: normal;
  text-align: left;
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.home-text-paragraph {
  font-family: "Karla";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #5e5e5e;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  font-style: normal;
}

.home-section-button {
  background: #fc8e44;
  color: #fff;
  border-color: #fc8e44;
  font-family: karla;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 1px;
  font-style: normal;
  padding: 22px 38px;
  border: 1px solid;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  text-transform: uppercase;
}

.home-section-button:hover{
  background-color: #fff;
  color: #fc8e44;
}

.home-text-more{
  color: #fc8e44;
  font-family: karla;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-style: normal;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.home-text-more:hover{
  color: #fda66c;
  /* transform: scale(1.05); */
}

.home-container {
  background-size: cover !important;
  position: relative;
  text-align: center;
  clear: both;
  height: 100vh;
}

.home-banner {
  height: 100%;
}

.home-caption {
  display: flex;
  gap: 30px;
  height: auto;
  width: 60%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-family: "karla";
}

.home-caption p {
  margin: 0;
  padding: 0;
  font-size: 2em;
}

.home-caption div {
  height: 2px;
  width: 50px;
  background-color: #fff;
}

.home-caption h1 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
}

.home-title-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.home-title {
  color: white;
  font-size: 60px;
  line-height: 90px;
  z-index: 5;
  padding: 0;
  margin: 0;
  font-family: "Playfair Display", serif;
}


@media (max-width: 1059px) {
  .home-text-caption {
    margin-bottom: 20px;
    letter-spacing: 2.7px;
    font-size: 16px;
  }
  
  .home-text-main {
    font-size: 28px;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .home-text-paragraph-big {
    text-align: justify;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .home-text-paragraph {
    text-align: justify;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .home-section-button {
    background: #fc8e44;
    color: #fff;
    border-color: #fc8e44;
    font-family: karla;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 1px;
    font-style: normal;
    padding: 22px 38px;
    border: 1px solid;
    border-radius: 0;
    cursor: pointer;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
    text-transform: uppercase;
  }
  
  .home-section-button:hover{
    background-color: #fff;
    color: #fc8e44;
  }
  
  .home-text-more{
    color: #fc8e44;
    font-family: karla;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1px;
    font-style: normal;
    cursor: pointer;
    -webkit-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
  }

  
.home-caption p {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
}

.home-caption div {
  height: 2px;
  width: 25px;
}

.home-caption h1 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
}

.home-title-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.home-title {
  color: white;
  font-size: 50px;
  line-height: 40px;
  z-index: 5;
  padding: 0;
  margin: 0;
  font-family: "Playfair Display", serif;
}
}

@media (max-width: 767px) {
  .home-caption {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    width: 100%;
    color: #fff;
    align-items: flex-start;
    justify-content: center;
    font-family: "karla";
  }

  .home-caption p {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
  }

  .home-caption div {
    height: 2px;
    width: 50px;
    background-color: #fff;
  }

  .home-caption h1 {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 1.5em;
    font-weight: 700;
  }
  .home-title-container {
    width: 80%;
  }
  .home-title {
    text-align: left;
    font-size: 40px;
    line-height: 60px;
  }
  .home-container {
    height: calc(100vh - 100px);
  }
  .home-banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
}


