body{
  overflow-x: hidden
}
.about-us-contribution{
    height: auto;
    width: 100%;
  }
  
.contributions-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.contributions-container img{
  object-fit: cover;
  min-height: 400px;
  width: 80%;
}
.contributions-orange-bg{
  position: relative;
  top:-150px;
  z-index: -2;
  height: 600px;
  width: 100%;
  background-color: #FC8E44;
}

.contributions-inner{
  position: relative;
  top:-450px;
  display: grid;
  gap: 40px;
  margin-bottom: -350px;
}

.contributions-header{
  display: flex;
  flex-direction: column;
  gap:10px;
  width: 90%;
  align-self: center;
  justify-self: right;
  position: relative;
}

.contributions-arrow-container{
  position: absolute;
  bottom: 16px;
  right: 10%;
}

.contributions-arrow{
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.contributions-arrow-disabled{
  background-color: transparent;
  border: none;
  outline: none;
  opacity: 0.5;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.contributions-card-container{
    width: 100%;
    padding-left: 5%;
    display: flex;
    gap: 80px;
    overflow-x: scroll;
    padding-bottom: 10px;
    scroll-behavior: smooth;
}


.contributions-card-container::-webkit-scrollbar{
  display: none;
}

.contributions-scrollbar{
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  height: 4px;
  background-color: rgb(168, 168, 168);
  position: relative;
  z-index: 0;
}

.contributions-scrollbar-thumb{
  position: relative;
  z-index: 1;
  height: 4px;
  background-color: #FC8E44;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

  
@media screen and (max-width: 576px) {
  .about-us-contribution{
    height: auto;
    width: 100%;
  }
  
  .contributions-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .contributions-container img{
    object-fit: cover;
    min-height: 300px;
    width: 80%;
  }
  
  .contributions-orange-bg{
    position: relative;
    top:-150px;
    z-index: -2;
    height: 500px;
    width: 100%;
    background-color: #FC8E44;
  }
  
  .contributions-inner{
    position: relative;
    top:-450px;
    display: grid;
    gap: 40px;
    bottom: -380px;
  }
  
  .contributions-header{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 90%;
    align-self: center;
    justify-self: right;
    position: relative;
  }
  
  .contributions-arrow-container{
    display: none;
  }
  
  .contributions-arrow{
    background-color: transparent;
    border: none;
    outline: none;
  }

  .contributions-card-container{
      width: 100%;
      padding-left: 5%;
      display: flex;
      gap: 40px;
      overflow-x: scroll;
  }

  .contributions-card-container::-webkit-scrollbar {
    height: 0px;
  }
  
}