.loading-indicator {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 9998;
  /* background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  background-size: auto; */
  /* background-image: url(https://n.foxdsgn.com/vestry/wp-content/uploads/2020/01/dlist-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.loading-display {
  display: none;
}
