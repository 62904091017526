.home-about-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
}

.home-about-container {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 90%;
}

.home-about-images {
  position: relative;
  height: 500px;
  width: 100%;
}

.home-about-images img {
  position: absolute;
  box-shadow: -20px 0 30px 0 rgba(0,0,0,.15);
}

.home-about-image-1 {
  width: 40%;
  height: 43%;
  z-index: 3;
  left: 22%;
  top: -30px;
  border-radius: 10px;
}
.home-about-image-2 {
  width: 40%;
  height: 45%;
  left: -40px;
  top: 160px;
  border-radius: 10px;
}
.home-about-image-3 {
  width: 40%;
  height: 45%;
  z-index: 2;
  top: 130px;
  right: 0;
  border-radius: 5px;
}
.home-about-image-4 {
  width: 42%;
  height: 42%;
  z-index: 2;
  bottom: -90px;
  left: 25%;
  border-radius: 5px;
}
.home-about-image-5 {
  z-index: 5;
  border-radius: 5px;
  width: 35%;
  height: 34%;
  right: 26%;
  bottom: 16%;
}

.home-about-content {
  align-self: center;
  width: 90%;
  padding-left: 20%;
  text-align: justify;
}

@media (max-width: 1059px) {
  .home-about-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .home-about-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    gap:20px;
    width: 90%;
  }

  .home-about-images{
      display: flex;
      height: 400px;
      gap: 10px;
      flex-wrap: wrap;
      padding-left: 10%;
  }
  .home-about-images img{
    position: relative;
  }

  .home-about-content {
    align-self: center;
    width: 100%;
    padding-left: 10%;
    text-align: justify;
  }
  .home-about-image-1 {
    width: 45%;
    /* height: 30%; */
    z-index: 2;
    left:auto;
    top: auto;
    border-radius: 10px;
  }
  .home-about-image-2 {
    width: 45%;
    /* height: 30%; */
    left: auto;
    top: auto;
    border-radius: 10px;
  }
  .home-about-image-3 {
    width: 45%;
    /* height: 30%; */
    z-index: 2;
    top: auto;
    right: auto;
    border-radius: 5px;
  }
  .home-about-image-4 {
    width: 45%;
    /* height: 30%; */
    z-index: 2;
    bottom: auto;
    left: auto;
    border-radius: 5px;
  }
  .home-about-image-5 {
      display: none;

  }
}

@media (max-width: 600px) {
}
